import Layout from "./common/layout"
import Head from "./common/Head"
import LivestreamPage from "pages/service/Livestream"

const LivestreamAd = () => {
  return (
    <>
      <Head
        title={"A-Z Livestream"}
        description={"A-Z Livestream"}
        showCaptcha={true}
      />
      <Layout displayApplyBtn={false}>
        <LivestreamPage />
      </Layout>
    </>
  )
}

export default LivestreamAd
